import React from 'react'

import logo from '../assets/img/logo-azul.webp'
import facebook from '../assets/img/facebook-azul.webp'
import instagram from '../assets/img/instagram-azul.webp'
import youtube from '../assets/img/youtube-azul.webp'


const Footer = () => {
  return (
    <div className='footer'>
          <div className='empty-space'></div>

        <div className='logo-wrapper'>
        <img className='logo-footer' src={logo} alt='Eco de los Andes agua de montaña'/>

        </div>
        <div className='social-media'>
          <a href='https://www.facebook.com/EcodelosAndes?locale=es_LA' target='_blank' rel="noreferrer">
            <img src={facebook} className='facebook' alt='facebook'/>
          </a>  
          <a href='https://www.instagram.com/ecodelosandes/?hl=es' target='_blank' rel="noreferrer">
            <img src={instagram} className='instagram' alt='instagram'/>
          </a>  
          <a href='https://www.youtube.com/@ecodelosandes' target='_blank' rel="noreferrer">
            <img src={youtube} className='youtube' alt='youtube'/> 
          </a>  
        </div>
    </div>
  )
}

export default Footer
import { Box, CircularProgress } from '@mui/material'
import React from 'react'


import logo from '../assets/img/logo-azul.webp'
import useAssetsLoader from '../hooks/useAssetsLoader';


const assets = [
  logo,
]

const Loading = () => {

  const assetsLoaded = useAssetsLoader(assets);

  return (
    <div className='loading'>
      {  !assetsLoaded ?
        <Box sx={{ display: 'flex' }}>
          <CircularProgress sx={{ color: '#0c1f5d' }}/>
        </Box>
        : <img className='logo' src={logo} alt='Eco de los Andes, agua de montaña'/>
    }
    </div>
  )
}

export default Loading
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/styles.css'
import { RouterProvider } from 'react-router-dom';
import { router } from './Router/router';
import Provider from "./Application/Provider";


//import "./assets/fonts/Gotham-Book-Font.ttf";


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <div className='App'>
      <Provider>
          <RouterProvider router={router}/>
      </Provider>
    </div>  
  </React.StrictMode>
);



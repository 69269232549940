/* import { useState, useEffect } from 'react';

const useVideosLoader = (videoSources) => {
    const [videosLoaded, setVideosLoaded] = useState(false);

    useEffect(() => {
        const loadVideo = (videoSrc) => {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.src = videoSrc;
                video.oncanplaythrough = () => resolve(videoSrc);
                video.onerror = () => reject(videoSrc);
            });
        };

        Promise.all(videoSources.map(videoSrc => loadVideo(videoSrc)))
            .then(() => setVideosLoaded(true))
            .catch(() => setVideosLoaded(false));
    }, [videoSources]);

    return videosLoaded;
};

export default useVideosLoader;
 */

import { useState, useEffect } from 'react';

const useVideosLoader = (videoSources) => {
    const [videosLoaded, setVideosLoaded] = useState(false);

    useEffect(() => {
        const fetchVideoBlob = async (videoSrc) => {
            const response = await fetch(videoSrc);
            const blob = await response.blob();
            return URL.createObjectURL(blob);
        };

        const loadVideoBlobs = async () => {
            try {
                const videoBlobPromises = videoSources.map(src => fetchVideoBlob(src));
                const videoBlobs = await Promise.all(videoBlobPromises);

                // Now that the videos are preloaded, update the state
                setVideosLoaded(true);

                // Return the videoBlobs if you need them for rendering
                return videoBlobs;
            } catch (error) {
                setVideosLoaded(false);
                console.error('Error loading videos:', error);
            }
        };

        loadVideoBlobs();
    }, [videoSources]);

    return videosLoaded;
};

export default useVideosLoader;


import React, { useState } from 'react'


import { Link } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import { FaBars } from 'react-icons/fa';


import logo from '../assets/img/logo.webp';
import facebook from '../assets/img/facebook.webp';
import instagram from '../assets/img//instagram.webp';



import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';




const NavBar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 820px)' });


    const linkProps = {
        smooth: true,
        duration: 500,
        offset: 0, // Adjust this value according to your nav bar height
        onClick: () => setState({ left: false })  // Close the drawer on link click
    };

    const [state, setState] = React.useState({
        right: false,
      });

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <IconButton
                onClick={toggleDrawer(false)}
                sx={{ position: 'absolute', top: 10, right: 10, color: '#fff' }}
            >
                <CloseIcon/>
            </IconButton>
            <Box sx={{ paddingTop: 5, backgroundColor: '#1f1851' }}>
                <List>
                    {['purity-of-origin', 'our-products', 'fine-dining', 'active-water', 'water-with-a-cause'].map((section, index) => (
                    <ListItem key={section} disablePadding>
                        <ListItemButton>
                        <Link to={section} {...linkProps}>
                            <ListItemText className='custom-font' primary={section.replace(/-/g, ' ').toUpperCase()} sx={{color: '#FFF' }} />
                        </Link>
                        </ListItemButton>
                        
                    </ListItem>
                    ))}
                </List>
            </Box>    
        </Box>
      );  

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };
    
    

  return (
    <nav>
      {isMobile ? (
        <>
          {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className='ham'>
            <div className='social'>
                <a href='https://www.instagram.com/ecodelosandes/?hl=es' target='_blank' rel="noreferrer">
                    <img src={facebook} className='facebook' alt='facebook'/>
                </a>
                <a href='https://www.facebook.com/EcodelosAndes?locale=es_LA' target='_blank' rel="noreferrer">
                    <img src={instagram} className='instagram' alt='instagram'/>
                </a>
            </div>
            <Button className='lala' sx={{padding:'30px', zIndex:'10000'}} onClick={toggleDrawer(anchor, true)}><FaBars size={30} color={'#fff'} /></Button>
          </div>  
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
          <div className='logo-mobile'>
            <img src={logo} alt=''/>
          </div>
        </React.Fragment>
      ))}
          {isOpen && (
            <ul className="mobile-menu">
                 <li>
                    <Link to="purity-of-origin" {...linkProps}>
                        PURITY OF ORIGIN
                    </Link>
                </li>
                <li>
                    <Link to="our-products" {...linkProps}>
                        OUR PRODUCTS
                    </Link>
                </li>
                <li>
                    <Link to="fine-dining" {...linkProps}>
                        FINE DINING
                    </Link>
                </li>
                <li>
                    <Link to="active-water" {...linkProps}>
                        ACTIVE WATER
                    </Link>
                </li>
                <li>
                    <Link to="water-with-a-cause" {...linkProps}>
                        WATER WITH A CAUSE
                    </Link>
                </li>
            </ul>
          )}
        </>
      ) : (
        <div className='nav-container'>
            <Link to="inicio" {...linkProps}>
                <img src={logo} className='logo' alt='Eco de los Andes, agua de montaña'/>
            </Link> 
            <div className='navegacion'>
                <div className='social-media'>
                    <a href='https://www.facebook.com/EcodelosAndes?locale=es_LA' target='_blank' rel="noreferrer">
                        <img src={facebook} className='facebook' alt='facebook'/>
                    </a> 
                    <a href='https://www.instagram.com/ecodelosandes/?hl=es' target='_blank' rel="noreferrer">
                        <img src={instagram} className='instagram' alt='instagram'/>
                    </a>    
                </div>
                <ul className="desktop-menu">
                    <li>
                        <Link to="purity-of-origin" {...linkProps}>
                            PURITY OF ORIGIN
                        </Link>
                    </li>
                    <span className='separador'>|</span>
                    <li>
                        <Link to="our-products" {...linkProps}>
                            OUR PRODUCTS
                        </Link>
                    </li>
                    <span className='separador'>|</span>
                    <li>
                        <Link to="fine-dining" {...linkProps}>
                            FINE DINING
                        </Link>
                    </li>
                    <span className='separador'>|</span>
                    <li>
                        <Link to="active-water" {...linkProps}>
                            ACTIVE WATER
                        </Link>
                    </li>
                    <span className='separador'>|</span>
                    <li>
                        <Link to="water-with-a-cause" {...linkProps}>
                            WATER WITH A CAUSE
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        
      )}
    </nav>
  )
}

export default NavBar
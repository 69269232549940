import React from "react";
import { createBrowserRouter } from "react-router-dom";

import ErrorPage from "../Routes/ErrorPage";

import Inicio from "../Routes/Inicio";
import Layout from "../Routes/Layout";

export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <Inicio />,
        },
      ],
    },
  ],
  { basename: "/" }
);

import { Link, useParams, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  const contactID = useParams().contactID;

  return (
    <div id="error-page" className="error-page">
      <h1>Oops!</h1>
      <br/>
      <p>Algo salió mal. Intente de nuevo más tarde.</p>
      <br/>
      <p><i>{error.statusText || error.message}</i></p>
      <br/>
      <Link to={'/' + contactID}>Volver al Home</Link>
    </div>
  );
}
import React from 'react'

const Image = (props) => {

    const { webp375, webp820, webp1280, webp1920, srcImg, className } = props;

  return (
      <picture>
        <source media={"(max-width: 500px)"} srcSet={`${webp375} 500w`}/>
        <source media={"(max-width: 820px)"} srcSet={`${webp820} 820w`}/>
        <source media={"(max-width: 1280px)"} srcSet={`${webp1280} 1280w`}/>
        <source media={"(max-width: 1920px)"} srcSet={`${webp1920} 1920w`}/>
        <source media={"(min-width: 1920px)"} srcSet={`${webp1920} 1920w`}/>
        <img 
            className={className}
            //loading="lazy"
            role="presentation"     
            src={srcImg}
            decoding="async"
            fetchpriority="high"
            alt=""
        />
      </picture>
  )
}

export default Image
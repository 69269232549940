import React from 'react'

import "../assets/fonts/Gotham-Book-Font.ttf";

import NavBar from '../Components/NavBar'


import { Element } from 'react-scroll';
import Image from '../Components/Image';
import useAssetsLoader from '../hooks/useAssetsLoader';
import useVideosLoader from '../hooks/useVideosLoader';
import useDeviceType from '../hooks/useDeviceType'; 

import { motion } from 'framer-motion';


import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import AnimatedNumbers from "react-animated-numbers";


import inicio from '../assets/img/inicio3.webp';

import logo from '../assets/img/logo.webp';
import facebook from '../assets/img/facebook.webp';
import instagram from '../assets/img//instagram.webp';

import inicioMobile from '../assets/img/inicioMobile.webp';
import purityOfOrigin1 from '../assets/img/purity-of-origin-1.webp';
import purityOfOriginMobile1 from '../assets/img/purity-of-origin-mobile-1.webp';
import purityOfOriginMobile2 from '../assets/img/purity-of-origin-mobile-2.webp';
import purityOfOriginMobile3 from '../assets/img/purity-of-origin-mobile-3.webp';

import purityOfOrigin2 from '../assets/img/purity-of-origin-2-1.webp';
import purityOfOrigin3 from '../assets/img/purity-of-origin-3-1.webp';
import purityOfOriginVideoPlaceholder from '../assets/img/purity-of-origin-video-placeholder.webp';
import ourProducts from '../assets/img/our-products.webp';
import ourProductsMobile from '../assets/img/our-products-mobile.webp';

import producto1 from '../assets/img/producto1.webp';
import producto2 from '../assets/img/producto2.webp';
import producto3 from '../assets/img/producto3.webp';
import producto4 from '../assets/img/producto4.webp';
import producto5 from '../assets/img/producto5.webp';
import producto6 from '../assets/img/producto6.webp';
import producto7 from '../assets/img/producto7.webp';
import producto8 from '../assets/img/producto8.webp';
import producto9 from '../assets/img/producto9.webp'; 


import fineDining from '../assets/img/fine-dining1.webp';
import fineDiningMobile from '../assets/img/fine-dining-mobile.webp';
import activeWater from '../assets/img/active-water1.webp';
import activeWaterMobile from '../assets/img/active-water-mobile.webp';
import waterWidthACausePlaceholder from '../assets/img/water-with-a-cause-video-placeholder.webp'
import waterWidthACause2 from '../assets/img/water-with-a-cause2.webp'
import waterWidthACause3 from '../assets/img/water-with-a-cause3.webp'
import waterWidthACause4 from '../assets/img/water-with-a-cause-4.webp'
import waterWidthACauseMobile from '../assets/img/water-with-a-cause-mobile.webp'
import waterWidthACauseMobile2 from '../assets/img/water-with-a-cause-mobile-2.webp'
import waterWidthACauseMobile3 from '../assets/img/water-with-a-cause-mobile-3.webp'


import logoEas from '../assets/img/logo-eas.webp';
import logoAWS from '../assets/img/logo-aws.webp'

import video1 from '../assets/videos/purityoforigin.mp4';
import video2 from '../assets/videos/waterwithacause.mp4';
import video3 from '../assets/videos/waterwithacause2.mp4';

import Footer from '../Components/Footer';
import Loading from '../Components/Loading';
import ScrollToTopButton from '../Components/ScrollToTopButton';


import arrowRight from '../assets/img/arrow-right.webp'
import arrowLeft from '../assets/img/arrow-left.webp'


const desktopAssets = [
  inicio,
  purityOfOriginVideoPlaceholder,
  purityOfOrigin1,
  purityOfOrigin2,
  purityOfOrigin3,
  ourProducts,
  fineDining,
  activeWater,
  waterWidthACausePlaceholder,
  waterWidthACause4,
  waterWidthACause2,
  waterWidthACause3, 
  producto1,
  producto2,
  producto3,
  producto4,
  producto5,
  producto6,
  producto7,
  producto8,
  producto9,
  logo,
  facebook,
  instagram
]

const mobileAssets = [
    inicioMobile,
    purityOfOriginMobile1,
    purityOfOriginMobile2,
    purityOfOriginMobile3,
    ourProductsMobile,
    fineDiningMobile,
    activeWaterMobile,
    waterWidthACauseMobile,
    waterWidthACauseMobile2,
    waterWidthACauseMobile3,
    producto1,
    producto2,
    producto3,
    producto4,
    producto5,
    producto6,
    producto7,
    producto8,
    producto9
  ];

const assetsVideo1 = [
    video1
]
const assetsVideo2 = [
    video2
]
const assetsVideo3 = [
    video3
]


const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    
    return (
        <div
            className={className}
            style={{ ...style, 
                backgroundImage: `url(${arrowLeft})`, 
                backgroundSize: 'contain', 
                backgroundRepeat: 'no-repeat', 
                width: '50px', 
                height: '50px', 
                margin: '0px 20px', 
                position: "absolute", 
                left: '43%', 
                top: 'inherit', 
                bottom: '50px', 
                zIndex: '1000' }}
                onClick={onClick}
        />
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, 
                backgroundImage: `url(${arrowRight})`, 
                backgroundSize: 'contain', 
                backgroundRepeat: 'no-repeat', 
                width: '50px', 
                height: '50px', 
                margin: '0px 20px', 
                position: "absolute", 
                right:'43%', 
                top: 'inherit', 
                bottom:'50px', 
                zIndex: '1000' }}
            onClick={onClick}
        />
    );
};


const Inicio = () => {

    const { ref, inView } = useInView({
        triggerOnce: false, // Trigger only once
        threshold: 0.2, // Trigger when 50% of the component is visible
    });
  

    const isMobile = useDeviceType(); 
    const assets = isMobile ? mobileAssets : desktopAssets;
  

    const assetsLoaded = useAssetsLoader(assets);
    const video1Loaded = useVideosLoader(assetsVideo1);
    const video2Loaded = useVideosLoader(assetsVideo2);
    const video3Loaded = useVideosLoader(assetsVideo3);


    const sliderSettings = {
        dots: true,
        infinite: true,
        lazyLoad: false,
        fade: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        arrows: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
      };
    
/*     const [verContenidoSeccion, setVerContenidoSeccion] = useState({
        purityOfOrigin: false,
        waterWithCause: false,
    });

    const verMas = (seccion) => {
        setVerContenidoSeccion(prevState => ({
            ...prevState,
            [seccion]: !prevState[seccion]
        }));
    }; */
    const [num, setNum] = React.useState(5000);


    if (!assetsLoaded) {
        return <Loading />;
    }

  return (
    <div>
        <Element name="inicio" className='inicio'>
            <div className='background-container'>
                <Image 
                    webp375={inicioMobile}
                    webp820={inicio}
                    webp1280={inicio}
                    webp1920={inicio}
                    srcImg={inicio}
                    className='inicio'
                />
                <div className='overlay-content'>
                    <NavBar/>
                </div>
            </div>
        </Element>
        <Element name="purity-of-origin" className="purity-of-origin">
            <div className='background-container'>
                <Slider {...{...sliderSettings}}> 
                    <div className='background-container'>
                    { !video1Loaded 
                      ? <Image
                            webp375={purityOfOriginVideoPlaceholder}
                            webp820={purityOfOriginVideoPlaceholder}
                            webp1280={purityOfOriginVideoPlaceholder}
                            webp1920={purityOfOriginVideoPlaceholder}
                            srcImg={purityOfOriginVideoPlaceholder}
                            className='fondo1'
                        />
                      : 
                      <div dangerouslySetInnerHTML={{ __html: `
                        <video autoPlay loop muted playsInline className="video-slide">
                            <source src=${video1} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        ` }}></div>
                    }
                        <motion.div 
                            className='overlay-content'
                            initial={{opacity: 0}}
                            whileInView={{opacity: 1}}
                            animate="visible"
                            viewport={{margin: "-100px"}}
                            transition={{ duration: 1, ease: "easeInOut" }}
                            ref={ref}
                        >
                            <div className='flex-container-video' >
                                <div className='col-1'>
                                    {/* <h2>PURITY OF ORIGIN</h2>
                                    <p><strong>Eco de los Andes</strong> es un agua de alta montaña que nace del deshielo de la Cordillera de los Andes, a más de <strong>5000 metros de altura</strong>. Un lugar que no tiene nada, pero lo tiene todo. Un lugar tan inmaculado que es protegido por la misma naturaleza. Un lugar donde la <strong>pureza</strong> se mantiene intacta, y da origen a un agua tan fresca como natural.</p>
                                    <p>Nuestra agua comienza su largo y descendente camino en lo alto de la montaña y viaja durante décadas hasta nuestra planta en Tunuyán, Mendoza. Su recorrido atraviesa las rocas de la Cordillera, las cuales filtran el agua de manera natural, creando una composición mineral que hace <strong>única</strong> a nuestra agua.</p>
                                    { verContenidoSeccion.purityOfOrigin &&
                                    <div className='content'>
                                        <p>Eco de los Andes es el resultado de un proceso ancestral, que se origina en las cumbres más altas y culmina en el acuífero de Tunuyán, donde se conserva de forma natural hasta ser embotellada. La esencia de la montaña se traduce en un agua que no tiene alteraciones por fuera de su proceso natural, llevando la pureza de la montaña en cada gota. </p>
                                        <button onClick={() => verMas('purityOfOrigin')} className='ver-mas'>{verContenidoSeccion.purityOfOrigin ? 'VER MENOS' : 'VER MÁS'}</button>
                                    </div> } 
                                    <p>Eco de los Andes es el resultado de un proceso ancestral, que se origina en las cumbres más altas y culmina en el acuífero de Tunuyán, donde se conserva de forma natural hasta ser embotellada. La esencia de la montaña se traduce en un agua que no tiene alteraciones por fuera de su proceso natural, llevando la pureza de la montaña en cada gota.</p>  */}
                                   <div className='bottom'>
                                        <h2>PURITY OF ORIGIN</h2>
                                        <p><strong>Eco de los Andes</strong> es un agua de alta montaña que nace del deshielo de la Cordillera de los Andes, a más de <strong>5000 metros de altura</strong>. Un lugar donde la pureza se mantiene intacta, y da origen a un agua fresca y natural.</p>
                                   </div>
                                </div>
                                <div className='col-2'>

                                {inView && (
/*                                     <CountUp 
                                        start={4900} 
                                        end={5000} 
                                        duration={4} 
                                        separator="" // No separator
                                        decimal=""   // No decimal point
                                        prefix="+"   // Add + sign before the count

                                    >
                                        {({ countUpRef }) => <h3 ref={countUpRef}></h3>}

                                    </CountUp> */
                                    <h3><span className="count-up-content"> 
                                        + 
                                        <AnimatedNumbers
                                            transitions={(index) => ({
                                            type: "spring",
                                            duration: index + 0.3,
                                            })}
                                            animateToNumber={num}
                                        />
                                        </span>              
                                    </h3>
                                )}
 
                                    <p>METROS DE</p>
                                    <p className='size'>ALTURA</p>
                                </div>
                            </div>
                        </motion.div> 
                    </div>
                    <div className='background-container'>
                        <Image 
                            webp375={purityOfOriginMobile3}
                            webp820={purityOfOriginMobile3}
                            webp1280={purityOfOrigin3}
                            webp1920={purityOfOrigin3}
                            srcImg={purityOfOrigin3}
                            className='fondo1'
                        />
                        <div className='overlay-content'>
                            <div className='flex-container'>
                                <div className='col-1'>
                                    <h2>PURITY OF ORIGIN</h2>
                                    <p>Desciende desde los picos nevados y, a través de un proceso de filtrado natural, incorpora de las rocas de la montaña un balance perfecto de minerales.</p>
                                </div>
                                <div className='col-2'>
                                    <h3>+5000</h3>
                                    <p>METROS DE</p>
                                    <p className='size'>ALTURA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='background-container'>
                        <Image 
                            webp375={purityOfOriginMobile1}
                            webp820={purityOfOriginMobile1}
                            webp1280={purityOfOrigin1}
                            webp1920={purityOfOrigin1}
                            srcImg={purityOfOrigin1}
                            className='fondo1'
                        /> 
                        <motion.div 
                            className='overlay-content'
                            initial={{opacity: 0}}
                            whileInView={{opacity: 1}}
                            animate="visible"
                            viewport={{margin: "-100px"}}
                            transition={{ duration: 1, ease: "easeInOut" }}
                        >
                            <div className='flex-container'>
                                <div className='col-1'>
                                    <h2>PURITY OF ORIGIN</h2>
                                    <p><strong>Eco de los Andes</strong> se origina en las cumbres más altas y culmina en el acuífero de Tunuyán, donde surge naturalmente de un pozo artesiano. La esencia de la montaña se mantiene en el agua, llevando su pureza en cada gota.</p>
                                </div>
                                <div className='col-2'>
                                    <h3>+5000</h3>
                                    <p>METROS DE</p>
                                    <p className='size'>ALTURA</p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    {/* <div className='background-container'>
                        <Image 
                            webp375={purityOfOriginMobile2}
                            webp820={purityOfOriginMobile2}
                            webp1280={purityOfOrigin2}
                            webp1920={purityOfOrigin2}
                            srcImg={purityOfOrigin2}
                            className='fondo1'
                        />
                        <div className='overlay-content'>
                            <div className='flex-container'>
                                <div className='col-1'>
                                    <h2>PURITY OF ORIGIN</h2>
                                    <p>Nuestra agua comienza su largo y descendente camino en lo alto de la montaña y viaja durante décadas hasta nuestra planta en Tunuyán, Mendoza. Su recorrido atraviesa las rocas de la Cordillera, las cuales filtran el agua de manera natural, creando una composición mineral que hace <strong>única</strong> a nuestra agua.</p>
                                </div>
                                <div className='col-2'>
                                    <h3>+5000</h3>
                                    <p>METROS DE</p>
                                    <p className='size'>ALTURA</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Slider>
            </div>
        </Element>
        <Element name="our-products" className="our-products">
            <div className='background-container'>
                <Image 
                    webp375={ourProductsMobile}
                    webp820={ourProductsMobile}
                    webp1280={ourProducts}
                    webp1920={ourProducts}
                    srcImg={ourProducts}
                    className='producto'
                />
                <motion.div 
                    className='overlay-content'
                    initial={{opacity: 0}}
                    whileInView={{opacity: 1}}
                    animate="visible"
                    viewport={{margin: "-100px"}}
                    transition={{ duration: 1, ease: "easeInOut" }}     
                >
                    <div className='container'>
                        <h2>OUR PRODUCTS</h2>
                        {/* <p><strong>Eco de los Andes</strong> se distingue por ser <strong>pura, fresca y liviana</strong>. Nuestra gran variedad <br/>de presentaciones están diseñadas para que puedas llevar la <strong>pureza de la montaña</strong> <br/>a donde sea que vayas.</p> */}
                        <p><strong>Eco de los Andes</strong> se distingue por ser <strong>pura, fresca y liviana</strong>. Nuestra gran variedad de presentaciones están diseñadas para que puedas llevar la <strong>pureza de la montaña</strong> a donde sea que vayas.</p>

                    <div className='variant'>
                        <Slider {...{ ...sliderSettings, arrows: true, slidesToShow: 5, slidesToScroll: 5, fade: false, speed: 800, responsive: [
                                    {
                                        breakpoint: 820,
                                        settings: {
                                          slidesToShow: 3,
                                          slidesToScroll: 3,
                                          infinite: true,
                                          dots: true
                                        }
                                      },
                                    {
                                        breakpoint: 500,
                                        settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        infinite: true,
                                        dots: true
                                        }
                                    }], 
                                }}>
                            <Image 
                                webp375={producto1}
                                webp820={producto1}
                                webp1280={producto1}
                                webp1920={producto1}
                                srcImg={producto1}
                                className='product'
                            />
                            <Image 
                                webp375={producto2}
                                webp820={producto2}
                                webp1280={producto2}
                                webp1920={producto2}
                                srcImg={producto2}
                                className='product'
                            />
                            <Image 
                                webp375={producto3}
                                webp820={producto3}
                                webp1280={producto3}
                                webp1920={producto3}
                                srcImg={producto1}
                                className='product'
                            />
                            <Image 
                                webp375={producto4}
                                webp820={producto4}
                                webp1280={producto4}
                                webp1920={producto4}
                                srcImg={producto1}
                                className='product'
                            />
                            <Image 
                                webp375={producto5}
                                webp820={producto5}
                                webp1280={producto5}
                                webp1920={producto5}
                                srcImg={producto1}
                                className='product'
                            />
                            <Image 
                                webp375={producto6}
                                webp820={producto6}
                                webp1280={producto6}
                                webp1920={producto6}
                                srcImg={producto1}
                                className='product'
                            />
                            <Image 
                                webp375={producto7}
                                webp820={producto7}
                                webp1280={producto7}
                                webp1920={producto7}
                                srcImg={producto1}
                                className='product'
                            />
                             <Image 
                                webp375={producto8}
                                webp820={producto8}
                                webp1280={producto8}
                                webp1920={producto8}
                                srcImg={producto1}
                                className='product'
                            />
                             <Image 
                                webp375={producto9}
                                webp820={producto9}
                                webp1280={producto9}
                                webp1920={producto9}
                                srcImg={producto1}
                                className='product'
                            />
                        </Slider>
                    </div>
                    </div>
                </motion.div>
            </div>
        </Element>
        <Element name="fine-dining" className='fine-dining'>
            <div className='background-container'>
                <Image 
                    webp375={fineDiningMobile}
                    webp820={fineDining}
                    webp1280={fineDining}
                    webp1920={fineDining}
                    srcImg={fineDining}
                    className='fine-dining'
                />
                <motion.div initial={{opacity: 0}}
                    whileInView={{opacity: 1}}
                    animate="visible"
                    viewport={{margin: "-100px"}}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    className='overlay-content'
                >
                    <div className='flex-container'>
                        <h2>FINE DINING</h2>
                        <p>La <strong>Escuela Argentina de Sommeliers</strong> elige hace 20 años a Eco de los Andes por ser el agua perfecta para acompañar los mejores vinos y enaltecer toda experiencia gastronómica.</p>
                        <p><strong>Eco de los Andes</strong> se destaca por su frescura, ligereza y equilibrio perfecto de minerales.</p>
                    </div>
                    <img src={logoEas} className='logo-eas' alt='logo EAS'/>
                </motion.div>
            </div>
        </Element>
        <Element name="active-water" className='active-water'>
            <div className='background-container'>
                <Image 
                    webp375={activeWaterMobile}
                    webp820={activeWaterMobile}
                    webp1280={activeWater}
                    webp1920={activeWater}
                    srcImg={activeWater}
                    className='active-water'
                />
                <motion.div    
                    className='overlay-content'
                    initial={{opacity: 0}}
                    whileInView={{opacity: 1}}
                    animate="visible"
                    viewport={{margin: "-100px"}}
                    transition={{ duration: 2, ease: "easeInOut" }}
                >
                    <div className='flex-container'>
                        <div className='col-1'>
                            <h2>ACTIVE WATER</h2>
                        </div>
                        <div className='col-2'>
                            <p><strong>Eco de los Andes</strong>, nacida en la alta montaña, es la compañera ideal para quienes buscan elevar su hidratación con la pureza de la montaña, y conectarse con la naturaleza.</p>
                        </div>
                    </div>
                </motion.div> 
            </div>
        </Element>
        <Element name="water-with-a-cause" className='water-with-a-cause'>
            <div className='background-container'>
                <Slider {...sliderSettings}> 
                <div className='background-container'>
                    { !video2Loaded 
                      ? <Image
                            webp375={waterWidthACausePlaceholder}
                            webp820={waterWidthACausePlaceholder}
                            webp1280={waterWidthACausePlaceholder}
                            webp1920={waterWidthACausePlaceholder}
                            srcImg={waterWidthACausePlaceholder}
                            className='fondo1'
                        />
                      : <div dangerouslySetInnerHTML={{ __html: `
                        <video autoPlay loop muted playsInline className="video-slide">
                            <source src=${video2} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        ` }}></div>
                        }
                        <motion.div 
                            className='overlay-content'
                            initial={{opacity: 0}}
                            whileInView={{opacity: 1}}
                            animate="visible"
                            viewport={{margin: "-100px"}}
                            transition={{ duration: 1, ease: "easeInOut" }}
                        >
                            <div className='container'>
                                <h2>WATER WITH A CAUSE</h2>
                                <div className='col'>
                                    <p className='texto'>En <strong>Eco de los Andes</strong>, nuestro propósito es <strong>seguir trabajando para mejorar</strong> la calidad de vida de las personas. Por eso, nos comprometemos a implementar iniciativas que protejan y restauren los recursos naturales de la región.</p>
                                </div>
                            </div> 
                        </motion.div>
                    </div>
                    <div className='background-container'>
                    { !video3Loaded 
                      ? <Image
                            webp375={waterWidthACause4}
                            webp820={waterWidthACause4}
                            webp1280={waterWidthACause4}
                            webp1920={waterWidthACause4}
                            srcImg={waterWidthACause4}
                            className='fondo1'
                        />
                      : <div dangerouslySetInnerHTML={{ __html: `
                        <video autoPlay loop muted playsInline className="video-slide">
                            <source src=${video3} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        ` }}></div>
                        }
                        <motion.div 
                            className='overlay-content'
                            initial={{opacity: 0}}
                            whileInView={{opacity: 1}}
                            animate="visible"
                            viewport={{margin: "-100px"}}
                            transition={{ duration: 1, ease: "easeInOut" }}
                        >
                            <div className='container'>
                                <h2>WATER WITH A CAUSE</h2>
                                <h6>REGENERACIÓN</h6>
                                <p className='subtexto'>En <strong>Eco de los Andes</strong> asumimos el compromiso de regenerar el ciclo del agua de la cuenca donde operamos trabajando junto al Departamento General de Irrigación (DGI) y la Universidad Nacional de Cuyo.</p>
                            </div> 
                        </motion.div>
                    </div>  
                    <div className='background-container'>
                        <Image 
                            webp375={waterWidthACauseMobile2}
                            webp820={waterWidthACauseMobile2}
                            webp1280={waterWidthACause2}
                            webp1920={waterWidthACause2}
                            srcImg={waterWidthACauseMobile2}
                            className='fondo1'
                        /> 
                        <motion.div 
                            className='overlay-content'
                            initial={{opacity: 0}}
                            whileInView={{opacity: 1}}
                            animate="visible"
                            viewport={{margin: "-100px"}}
                            transition={{ duration: 1, ease: "easeInOut" }}
                        >
                            <div className='container'>
                                <h2>WATER WITH A CAUSE</h2>
                                <h6>AWS</h6>
                                <p className='subtexto'>En 2023, Eco de los Andes se convirtió en la primera empresa argentina en obtener la Certificación Platino de la Alliance for Water Stewardship. Se trata de la máxima distinción existente, otorgada por nuestra gestión responsable del agua en la planta de Tunuyán, Mendoza.</p>
                                <br/>
                                <p className='subtexto'>Para saber más sobre AWS, ingresá <a className='link' href='https://a4ws.org/about/' target='_blank' rel="noreferrer">acá</a></p>
                            </div> 
                            <img className='logo-aws' src={logoAWS} alt=''/>
                        </motion.div>
                    </div>  
                    <div className='background-container'>
                        <Image 
                            webp375={waterWidthACauseMobile3}
                            webp820={waterWidthACauseMobile3}
                            webp1280={waterWidthACause3}
                            webp1920={waterWidthACause3}
                            srcImg={waterWidthACauseMobile3}
                            className='fondo1'
                        /> 
                        <motion.div 
                            className='overlay-content'
                            initial={{opacity: 0}}
                            whileInView={{opacity: 1}}
                            animate="visible"
                            viewport={{margin: "-100px"}}
                            transition={{ duration: 1, ease: "easeInOut" }}
                        >
                            <div className='container'>
                                <h2>WATER WITH A CAUSE</h2>
                                <h6>PACKAGING</h6>
                                <p className='subtexto'>En <strong>Eco de los Andes</strong> tenemos como prioridad trabajar en la circularidad del PET para transformar el residuo en un recurso.</p>
                                <p className='subtexto'>Todas nuestras botellas están diseñadas para ser 100% reciclables.</p>
                            </div> 
                        </motion.div>
                    </div>     
                    </Slider>
                {/* <div className='overlay-content'>
                   <div className='container'>
                        <h2>WATER WITH A CAUSE</h2>
                        <p>El <strong>Valle de Uco</strong>, en la provincia de Mendoza, es reconocido mundialmente por su producción frutihortícola. El Río Tunuyán, que nace en los Andes, alimenta esta región, siendo vital para la agricultura. Sin embargo, la provincia enfrenta una crisis hídrica debido a la falta de precipitaciones y el agotamiento de los acuíferos. Junto al Departamento General de Irrigación y la Universidad Nacional de Cuyo, nos comprometemos a implementar medidas para <strong>preservar este recurso vital</strong>. Juntos, buscamos proteger el medio ambiente y generar un impacto positivo en la comunidad.</p>
                        { verContenidoSeccion.waterWithCause &&
                        <div className='content'>
                            <p>En Eco de los Andes el agua es el corazón de nuestra actividad. Desde el inicio de las operaciones de nuestra planta en Tunuyán, en Mendoza, llevamos a cabo diversas estrategias para la gestión responsable del agua. Como parte de este camino y reafirmando nuestro compromiso, colaboramos con la Alliance for Water Stewardship (AWS), una alianza comprometida a proteger los recursos hídricos. En 2023, la planta de Eco de los Andes obtuvo la Certificación Platino de la AWS, máximo reconocimiento de la entidad por el uso responsable del agua.</p>
                        </div> }
                        <button onClick={() => verMas('waterWithCause')} className='ver-mas'>{verContenidoSeccion.waterWithCause ? 'VER MENOS' : 'VER MÁS'}</button>
                   </div>
                </div> */}
{/*                 <div className='overlay-content'>
                   <div className='container'>
                        <h2>WATER WITH A CAUSE</h2>
                        <p className='texto'>En Eco de los Andes, el agua es el corazón de nuestra actividad. Desde el inicio de nuestras operaciones en Tunuyán, Mendoza, implementamos estrategias para gestionar el agua responsablemente y generar un impacto positivo en la cuenca donde operamos.</p>
                    </div>
                </div>  */}   
            </div>    
        </Element>
        <ScrollToTopButton />
        <Footer />
    </div>
  )
}

export default Inicio